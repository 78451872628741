.k-button-solid-primary{
    border-radius: 0rem;
    border-color: $opal-green-dark;
    background-color: $opal-green-dark;
}

.k-button-solid-primary:active,
.k-button-solid-primary:hover,
.k-button-solid-focus:focus,
.k-button-solid-primary.k-focus{
    border-radius: 0rem;
    border-color: $opal-green-hover;
    background-color: $opal-green-hover;
}

.k-button-outline-primary{
    border-color: $opal-green-dark;
    background-color: $opal-green-dark;
    color: $opal-green-dark;
}

.k-button-outline-primary:hover,
.k-button-outline-primary:active,
.k-button-outline-primary:focus{
    color: $opal-green-dark;
    border-color: $opal-green-dark;
}

.k-button{
  font-family: $opal-font-family !important;
  font-size: 15px !important;
}