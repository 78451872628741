.k-label{
    color: $opal-green;
    font-size: 15px;
    font-family: $opal-font-family;
    
}

.k-form-field{
    margin-bottom: 1rem;
}

.k-label-orange label{
    color: $opal-orange;
}

.k-switch-label.k-label{
    margin-bottom: 0px !important;
    margin-left: 8px !important
}