.sidenav-inner .sidenav {
  z-index: 1000;
}

.sidenav-inner ~ .sidenav-backdrop {
  z-index: 999 !important;
}

@media (min-width: 1400px) {
  #main-navbar,
  footer.fixed-bottom {
    padding-left: 280px;
  }
}

.sidenav-link{
  padding: 0px !important;
  border-radius: 0px;
  transition: 0s;
}

.sidenav-item{
  color: $white !important;
}

.fa{
  color: $white !important;
}

.sidenav-menu{
  padding: 0px !important;
}

// 
.sidenav-link.active{
  border-right: 5px solid $opal-orange !important;
  opacity: 85%;
  transition: 0s;
}

.sidenav-link:hover{
  border-right: 5px solid $opal-green-light;
  opacity: 85%;
  transition: 0s;
}

.sidenav-item{
  color: $opal-white !important;
}

.sidenav-menu{
  padding: 0px !important;
}


.sidenav-item-left{
  background-color: $opal-green-dark;
  opacity: 95%;
  min-width: 77px;
}

.sidenav-item-right{
  background-color: $opal-green-dark;
  min-width: 203px;
}

.logo-img{
  
  width: 50px;
}

.brand{
  color: $opal-grey;
}

.navbar{
  box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
  padding-top: 0.5625rem;
}

.navbar{
    box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
    padding: 0.5rem 0rem;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
  }
  
  .sidenav-collapse{
    .sidenav-link{
      height: 2.5rem;
      font-size: 0.84rem;
    }
  }
  
  .sidenav-main-content{
      overflow-y: auto;
      // margin-top: 0.50rem;
      // margin-bottom: 0.50rem;
      background-color: $opal-grey-opacity-20;
      min-height: calc(100vh - 60px);
      max-height: calc(100vh - 66px);
  }
  
  .sidenav-main-content > router-outlet + * {
      flex: 1 1 auto !important;
      display: flex !important;
      max-height: calc(100vh - 60px);
  }

  .navbar h1{
    font-size: 25px;
  }

.sidenav-icon-rotate{
    -moz-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.sidenav-icon-rotate.rotate-down{
    -moz-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
    transform:rotate(90deg);
}

.brand-image {
    background: $opal-green-lin-gradient;
}

.brand-wrapper{
  min-height: 110px;
  img{
    max-width: 45px;
  }
}

mdb-sidenav .rotate-icon{
  margin-right: 0.6rem !important;
}

.user-info-dialog.k-window kendo-window-titlebar{
  display: none;
  
}

.usar-avatar{
  position: relative;
  width: 70px; 
  height: 70px;
  text-align: center;
  color: $opal-green-dark;
  vertical-align: middle;
}

.user-avatar-initials{
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-weight: 300;
  transform: translate(-50%, -50%);
  font-size: 24px; 
  white-space: nowrap;
}

.user-avatar-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 70px;
}

.usar-avatar-small{
  position: relative;
  width: 32px; 
  height: 32px;
  text-align: center;
  color: $opal-green-dark;
  vertical-align: middle;
}

.user-avatar-initials-small{
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  font-weight: 300;
  transform: translate(-50%, -50%);
  font-size: 14px; 
  white-space: nowrap;
}

.user-avatar-icon-small{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.usar-avatar-small:hover .fa-hover{
  color: $opal-orange;
}