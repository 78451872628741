// TODO: Defines styles that will be applied only to components for release notes
.release-notes h1{
  font-weight: 500;
  color: $opal-green;
}

.release-notes h2,
.release-notes h3,
.release-notes h4,
.release-notes h5,
.release-notes h6{
  font-weight: 600 !important;
}

.release-notes h1{
  font-size: 1.5rem;
}

.release-notes h2{
  font-size: 1.4rem;
}

.release-notes h3{
  font-size: 1.3rem;
}

.release-notes h4{
  font-size: 1.2rem;
}

.release-notes h5{
  font-size: 1.1rem;
}

.release-notes h6{
  font-size: 1.0rem;
}

.release-notes section{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.release-info-table{
  border-width: 1px;
  border-style: solid;
}

.release-info-table td{
  padding: 8px 15px !important;
  height: 1rem !important;
  line-height: 2.2 !important;
  border-width: 1px;
  border-color: $opal-grey-light-1;
  border-style: solid;
}

.release-info-table td:nth-child(1){
  background-color: $opal-grey-light-2;
  font-weight: 500;
  min-width: 200px;
}

.release-info-table td:nth-child(2){
  min-width: 400px;
}

.release-info-table table {
  border: 1px solid $opal-grey-light-1;
}

.nav-link.toc-link{
  color: #212529;
  cursor: pointer;
  padding: 5px 0px 5px 0px ;
  font-size: 16px !important;
}

.nav-link.toc-link:hover{
  color: $opal-orange;
}

.release-notes img{
  box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
}

.feature-title .back-to-toc{
  visibility: hidden;
}

.back-to-toc{
  color: $opal-green;
  cursor: pointer;
}

.back-to-toc:hover{
  color: $opal-orange;
}

.feature-title:hover .back-to-toc{
  visibility: visible;
}

#productDownloadsGrid .k-grid-content tbody tr td:nth-child(4) div,
#productDownloadsGrid .k-grid-content tbody tr td:nth-child(5) div,
#productDownloadsGrid .k-grid-header-table thead tr th:nth-child(4) .k-link,
#productDownloadsGrid .k-grid-header-table thead tr th:nth-child(5) .k-link{
  justify-content: center;
}

#productDownloadsGrid .k-grid-content tbody tr td p{
  margin: 0px;
}

#bugAndIssueFixesGrid .k-grid-content tbody tr td:nth-child(1) div,
#bugAndIssueFixesGrid .k-grid-content tbody tr td:nth-child(5) div,
#bugAndIssueFixesGrid .k-grid-header-table thead tr th:nth-child(1) .k-link,
#bugAndIssueFixesGrid .k-grid-header-table thead tr th:nth-child(5) .k-link{
  justify-content: center;
}

#bugAndIssueFixesGrid .k-grid-content tbody tr td p{
  margin: 0px;
}


#migrationStepsGrid .k-grid-content tbody tr td p{
  margin: 0px;
}

#migrationStepsGrid .k-grid-content tbody tr td:nth-child(1) div
#migrationStepsGrid .k-grid-header-table thead tr th:nth-child(1) .k-link{
  justify-content: center;
}


#prerequisitesGrid .k-grid-content tbody tr td:nth-child(2) div,
#prerequisitesGrid .k-grid-header-table thead tr th:nth-child(2) .k-link{
  justify-content: center;
}

#compatibilityGrid .k-grid-content tbody tr td:nth-child(2) div,
#compatibilityGrid .k-grid-content tbody tr td:nth-child(3) div,
#compatibilityGrid .k-grid-header-table thead tr th:nth-child(2) .k-link,
#compatibilityGrid .k-grid-header-table thead tr th:nth-child(3) .k-link{
  justify-content: center;
}

#thirdPartyHotfixesGrid .k-grid-content tbody tr td:nth-child(2) div,
#thirdPartyHotfixesGrid .k-grid-content tbody tr td:nth-child(3) div,
#thirdPartyHotfixesGrid .k-grid-content tbody tr td:nth-child(6) div,
#thirdPartyHotfixesGrid .k-grid-header-table thead tr th:nth-child(2) .k-link,
#thirdPartyHotfixesGrid .k-grid-header-table thead tr th:nth-child(3) .k-link,
#thirdPartyHotfixesGrid .k-grid-header-table thead tr th:nth-child(6) .k-link{
  justify-content: center;
}

.k-editor-resize-wrap-element,
.k-editor-resize-wrap-element table{
  max-width: 578px !important;
}

.k-editor-resize-wrap-element table tbody tr:first-child {
  color: #212529;
  font-weight: bold;
  background-color: $opal-grey-light-2;
  padding: 10px;
}

.feature-table tbody tr:first-child{
  color: #212529;
  font-weight: 500;
  background-color: $opal-grey-light-2;
}

.feature-table{
  margin-bottom: 16px;
}

.feature-table tbody{
  color: #212121;
  font-weight: 350;
}

.feature-table p{
  margin: 0px;
}

table.feature-table > tbody > tr > td {
  padding: 8px 15px !important;
  line-height: 2.2 !important;
  border: 1px solid $opal-grey-light-1 !important; 
}

.feature-table {
  min-width: 100%;
}

.release-notes-table {
  border-radius: 0px;
  border: solid 1px $opal-grey-light-1;
}

.release-notes-table .k-column-title{
  color: #212529;
}

.release-notes-table table thead th{
  background-color: $opal-grey-light-2;
}

.release-notes-table table > thead > tr > th.k-header.k-table-th,
.release-notes-table table > tbody > tr > td.k-table-td{
  border-right: 1px solid $opal-grey-light-1 !important;
}

.release-notes-table table > thead > tr > .k-header.k-table-th:last-child,
.release-notes-table table > tbody > tr > .k-table-td:last-child{
  border-right: 0px !important;
}
.release-notes-table table > tbody > tr.k-table-row:hover{
  background-color: transparent !important;
}

.toc-button{
  width: 24px;
}