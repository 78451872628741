.bg-opal-green{
    background-color: $opal-green !important;
}

.bg-opal-dark-green{
  background-color: $opal-green-dark !important;
}

.bg-opal-green-rad-gradient{
    background: $opal-green-rad-gradient;
}

.opal-white{
  color: $opal-white !important;
}

.opal-green{
  color: $opal-green;
}

.opal-dark-green{
  color: $opal-green-dark;
}