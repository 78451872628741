// Border color of datetime picker
.k-datetimepicker.k-focus::after{
    border-color: $opal-green !important;
}

// Selected day color
.k-calendar.k-calendar-td.k-selected.k-link, 
.k-calendar-td.k-selected:hover .k-link,
.k-calendar-td.k-selected .k-link{
    border-color: $opal-green !important;
    background-color: $opal-green !important;
}

// Today button
.k-calendar-nav-today{
  color: $opal-green !important;
}

// Current day of month color (when not selected)
.k-calendar-td.k-today span.k-link{
    color: $opal-green !important;
}

// Current day of month color (when selected)
.k-calendar-td.k-today.k-selected span.k-link{
    color: white !important;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
    color: $opal-green;
}

// Today text(button) color
.k-today.k-calendar-nav-today, .k-today.k-selected{
    color: $opal-green !important;
}

// Now text(button) color
.k-time-now{
    color: $opal-orange !important;
}

