.m-2-5{
  margin: 12px;
}

.my-2-5{
  margin-top: 12px;
  margin-bottom: 12px;
}

.mx-2-5{
  margin-right: 12px;
  margin-left: 12px;
}

.mb-2-5{
  margin-bottom: 12px;
}

.mt-2-5{
  margin-top: 12px;
}

.ms-2-5{
  margin-left: 12px;
}

.me-2-5{
  margin-right: 12px;
}

.p-2-5{
  padding: 12px;
}

.py-2-5{
  padding-top: 12px;
  padding-bottom: 12px;
}

.px-2-5{
  padding-left: 12px;
  padding-right: 12px;
}

.pb-2-5{
  padding-bottom: 12px;
}

.pt-2-5{
  padding-top: 12px;
}

.ps-2-5{
  padding-left: 12px;
}

.ps-4-5{
  padding-left: 32px;
}

.pe-2-5{
  padding-right: 12px;
}