.fa{
    color: $opal-white !important;
}

.fa-hover:hover{
    color: $opal-orange;
}

.fa-disabled{
    color: $opal-grey-dark
}

.fa-selected{
    color: $opal-orange;
}

.fa-3xl{
    font-size: 2.5em;
}

.fa-4xl{
    font-size: 3.5em;
}

.fa-5xl{
  font-size: 4em;
}

.fa-6xl{
    font-size: 5em;
}

.fa-7xl{
    font-size: 5.5em;
}