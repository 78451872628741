.k-switch-on .k-switch-thumb{
    background-color: $opal-green;
}

.k-switch-on .k-switch-track{
    background-color: $opal-green-light;
}

.opal-dark-green.k-switch.k-switch-on .k-switch-thumb{
    background-color: $opal-green-dark !important;
}

.opal-dark-green.k-switch.k-switch-on .k-switch-track{
    background-color: $opal-green-light !important;
}
