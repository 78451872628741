
body {
  font-family: $opal-font-family;
  font-size: 15px;
}

p{
  font-size: 15px;
}

// Addiotional display-* classes for bootstrap.
.display-7{
    font-weight: 300;
    font-size: 30px;
  }
  
.display-8{
  font-weight: 300;
  line-height: 1.3rem;
  font-size: 1.5rem;
}

.display-9{
  font-weight: 300;
  font-size: 1.2rem;
}

.display-10{
  font-weight: 300;
  font-size: 15px;
}

.display-11{
  font-weight: 300;
  font-size: 0.7rem;
}

.opal-green-text{
  color: $opal-green;
}

.opal-dark-green-text{
  color: $opal-green-dark;
}

.opal-white-text{
  color: $opal-white !important;
}

.opal-orange-text{
  color: $opal-orange !important;
}

.opal-grey-text{
  color: $opal-grey-dark !important;
}

.opal-red-text{
  color: $opal-alert !important;
}

.default-color{
  color: $default-color;
}

h1.appTitle:hover {
  cursor: pointer;
  color: $opal-green;
}


.highlighted-match{
    font-weight: 600;
    color: $opal-green;
    font-style: normal;
}

.log-message{
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
}