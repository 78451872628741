
$opal-green-dark: #025461;
$opal-green-light: #8fb2b8;
$opal-green: #009ba6;
$opal-green-rad-gradient: radial-gradient(circle, #037780, #025461);
$opal-green-lin-gradient: linear-gradient(to bottom, #037780, #025461);
$opal-green-opacity-20: #009ba633;
$opal-green-opacity-25: #009ba640;
$opal-green-hover: #047181;
$opal-green-active: #047181;
$opal-orange: #ff9800;
$opal-orange-opacity-20: #ff980033;
$opal-orange-opacity-25: #ff980040;
$opal-orange-dark: #ff9800;
$opal-alert: red;
$opal-red: red;
$opal-red-opacity-20: #ff000033;
$opal-ok: green;
$opal-input-bg: #f5f5f5;
$opal-grey: #bdbdbd;
$opal-grey-opacity-20: #bdbdbd33;
$opal-grey-opacity-25: #bdbdbd40;
$opal-grey-dark: #9d9d9d;
$opal-grey-light-1: #e0e0e0;
$opal-grey-light-2: #f8f8f8;
$opal-white: white;
$default-color: rgba(0, 0, 0, 0.87)
