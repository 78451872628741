// these classes resolve issue with table resizing inside editor

.ProseMirror .tableWrapper {
    overflow-x: auto;
    margin: 1em 0;
  }
  
  .ProseMirror table {
    margin: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
  }
  
  .ProseMirror td,
  .ProseMirror th {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 8px 8px;
    line-height: 20px !important;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
  }
  
  .ProseMirror th {
    font-weight: bold;
    text-align: left;
  }
  
  .ProseMirror .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none;
  }
  
  .ProseMirror.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
  
  /* Give selected cells a blue overlay */
  .ProseMirror .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
  }
  
  /* Resizing */
  .k-editor-resize-wrap-element {
    display: inline-block;
    position: relative;
  }
  
  .ProseMirror .row-resize-handle {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translate(0, 50%);
    height: 4px;
    z-index: 20;
    background-color: #adf;
    pointer-events: none;
  }
  
  .ProseMirror.resize-cursor-vertical {
    cursor: sn-resize;
    cursor: row-resize;
  }
  
  .k-editor-resize-wrap-element table td p,
  .k-editor-resize-wrap-element table th p {
    margin: 0 auto;
  }
  
  .ProseMirror[contenteditable='false'] .k-editor-resize-handle,
  .ProseMirror[contenteditable='false'] .k-editor-resize-handles-wrapper,
  .ProseMirror[contenteditable='false'] .row-resize-handle,
  .ProseMirror[contenteditable='false'] .column-resize-handle {
    display: none;
  }
  
  .ProseMirror[contenteditable='false'] table {
    pointer-events: none;
  }
  
  .ProseMirror[contenteditable='false'] .ProseMirror-selectednode {
    outline: none;
  }
  
  .ProseMirror[contenteditable='false'] {
    cursor: unset !important;
  }
  
  .k-toolbar-button-group > button.k-button{
    border-radius: 0px !important;
  }
  
  .k-toolbar-renderer  button.k-button{
    border-radius: 0px !important;
  }
  
  .k-toolbar-renderer .k-dropdownlist{
    line-height: 1.35 !important;
  }
  
  .k-editor-content {
    font-family: "Open Sans";
    p {
      margin-bottom: 8px;
    }
  
    h3 {
      font-size: 1.5rem;
    }
    
    h4 {
      font-size: 1.4rem;
    }
  
    h5 {
      font-size: 1.3rem;
    }
    
    img{
      box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
    }
  
  }