

.opal-tooltip-green *,
.opal-tooltip-orange *,
.opal-tooltip-red * {
  color: $opal-white;
  font-size: 15px;
  max-width: 300px;
  border-radius: 0px;
  z-index: 999999;
}

.opal-tooltip-green .k-callout ,
.opal-tooltip-orange .k-callout ,
.opal-tooltip-red .k-callout {
  background-color: transparent;
}

.opal-tooltip-green.k-animation-container ,
.opal-tooltip-orange.k-animation-container,
.opal-tooltip-red.k-animation-container{
  z-index: 100000 !important;
}

.opal-tooltip-green *{
  background: $opal-green;
}

.opal-tooltip-green .k-callout {
  color: $opal-green;
}

.opal-tooltip-orange *{
  background: $opal-orange;
}

.opal-tooltip-orange .k-callout {
  color: $opal-orange;
}

.opal-tooltip-red *{
  background: $opal-red;
}

.opal-tooltip-red .k-callout {
  color: $opal-red;
}
