.k-listview-bordered > .k-listview-header,
.k-listview-header{
  border-bottom-width: 0px !important;
}

.k-filter-menu-container .k-actions.k-button-group {
  display: none;
}


.k-listview ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.k-listview {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}