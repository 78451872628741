.k-tabstrip-items > li.k-item.k-active::after{
    border-color: $opal-green;
}

.k-tabstrip > .k-content{
    padding-left: 5px;
    padding-right: 5px;
    
}

.tab-content, .tab-content > router-outlet + * {
    flex: 1 1 auto !important;
    display: flex !important;
}

.k-tabstrip > .k-content {
    padding: 0px !important;
}

.k-tabstrip {
  font-family: $opal-font-family !important;
  font-size: 15px !important;
}

.k-tabstrip-content{
  padding-top: 12px !important;
  padding-bottom: 0px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.k-tabstrip-content:focus, .k-tabstrip-content.k-focus,
.k-tabstrip > .k-content:focus,
.k-tabstrip > .k-content.k-focus {
    outline-style: none;
}

.k-tabstrip-p0 .k-tabstrip-content{
  padding: 0px !important;
}

.k-tabstrip-content.k-tabstrip-content-p0{
  padding: 0px !important;
}