reset-password .k-overlay,
email-confirmation .k-overlay,
register-user .k-overlay,
privacy-policy .k-overlay,
login .k-overlay{
    background: $opal-green-rad-gradient;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}

.k-window{
  font-family: $opal-font-family !important;
}

.k-popup.k-grid-filter-popup{
  min-width: 250px;
  max-width: 450px;
}

.k-popup.k-grid-filter-popup .k-filter-menu-container{
  min-width: 250px;
  max-width: 450px;
}