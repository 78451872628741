.k-window-titlebar{
    padding: 16px 16px;
}

.k-window-content{
    padding: 0px !important;
}

.k-dialog-content{
    display: flex;
    flex-direction: column;
}

.k-window-actions-custom div.k-window-titlebar-actions{
    display: none;
}