.k-grid{
    border-radius: 5px;
    border: 0px;
}

.k-grid-header th{
    font-weight: $opal-font-weight-1;
    height: 1rem !important;
    line-height: 2.2 !important;
    color: $opal-green;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif !important;
}

.k-grid th{
    padding:8px 15px !important;
    height: 1rem !important;
    line-height: auto;
}

.k-grid th span.k-cell-inner span{
    padding-top: 0px;
    line-height: auto;
}

.k-grid-header {
    margin-right: -17px;
    font-size: $opal-font-size-1;
    
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif !important;
    border-bottom: 1px solid opal-grey-light-1 !important;
    border-top: 0px !important;
}

.k-grid-content {
    margin-right: -17px;
}

.k-grid.k-grid-light td{
    border-bottom: 0px !important;
    padding: 10px 15px !important;
}

.k-grid td{
    border-bottom: 1px solid opal-grey-light-1 !important;
    padding: 10px 15px !important;
}

.k-grid
{
    border-radius:5px;
    font-size: $opal-font-size-1;
    font-weight: $opal-font-weight-1;
    overflow:hidden;
}

.k-grouping-header
{
    border-radius:5px 5px 0 0;
    overflow:hidden;
}
.k-grid-pager
{
    border-radius:0 0 5px 5px;
    overflow:hidden;
    border-color: $opal-grey-light-1;
}

.k-pager-numbers .k-link.k-selected{
    color: white;
    background-color: $opal-green;
}

input[kendofilterinput]{
    padding-left: 0px;
}

input[kendofilterinput]:focus{
    border-bottom-color: $opal-green !important;
}

td[kendogridfiltercell]  button{
    border-radius: 0px !important;
    height: 36px !important;
    border: 0px !important;
}

input[kendofilterinput]{
    padding-left: 0px;
}

.k-grid th{
    border-right-width: 0px !important;
    border-left-width: 0px !important;
}

.k-table-md{
  font-size: 15px !important;
  font-family: $opal-font-family !important;
}

.k-grid-header .k-table-th {
  font-weight: 500 !important;
  font-size: 15px !important;
  font-family: $opal-font-family !important;
}

col.k-sorted,
.k-table-th.k-sorted {
    background-color: inherit;
}

.k-grid td.k-selected,
.k-grid .k-table-row.k-selected > td,
.k-grid .k-table-td.k-selected,
.k-grid .k-table-row.k-selected > .k-table-td {
    background-color: color-mix(in srgb, var(--kendo-color-on-app-surface, #212121) 8%, transparent) !important;
}

table.k-grid-table div.content-ok {
    color: $opal-ok;
}

table.k-grid-table div.content-alert {
    color: $opal-alert;
}

.k-grid-toolbar{
    border-color: rgba(0, 0, 0, 0.12);
}