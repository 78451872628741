.snack-title{
    font-size: 25px;
    color: white;
}

.snack-close-button{
    position: absolute;
    right: 9px;
    top: 14px;
    color: white;

    cursor: default;
}

.mat-snack-bar-container{
    background-color: rgb(50,50,50);
}
  
.snack-message{
    color: white;
}

.snack-success{
    color: $opal-green;
}

.snack-error{
    color: $opal-alert; 
}
.snack-warning{
    color: $opal-orange;
}

.snack-info{
    color: $opal-green;
}