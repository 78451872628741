@font-face {
  font-family: "Open Sans";
  src: url("../../webfonts/OpenSans.ttf") format("truetype");
}

$opal-font-weight-1: 350;
$opal-font-weight-2: 400;
$opal-font-weight-3: 500;

$opal-font-size-1: 1rem;
$opal-font-family: "Open Sans";
//$opal-font-family: "Roboto";