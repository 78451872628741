
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mdb-angular-dashboards-primary: mat.define-palette(mat.$indigo-palette);
$mdb-angular-dashboards-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mdb-angular-dashboards-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".


$mdb-angular-dashboards-theme: mat.define-light-theme((
  color: (
    primary: $mdb-angular-dashboards-primary,
    accent: $mdb-angular-dashboards-accent,
    warn: $mdb-angular-dashboards-warn,
  ),

));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mdb-angular-dashboards-theme);

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
/* You can add global styles to this file, and also import other style files */
// @import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
// @import "@fortawesome/fontawesome-free/scss/solid.scss";
// @import "@fortawesome/fontawesome-free/scss/regular.scss";
// @import "@fortawesome/fontawesome-free/scss/brands.scss";

@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "assets/css/fa-pro.min.css";
@import "assets/scss/partner.center.scss";

html,
body {
    height: 100%;
    overflow-y: hidden;
}




/* Custom styles*/
