.color-alert{
    color: $opal-alert;
}
  
.color-ok{
  color: $opal-ok;
}

.color-primary{
  color: $opal-green;
}

.color-grey{
  color: $opal-grey;
}

.cursor-pointer{
  cursor: pointer;
}

.separator{
    border-bottom: 1px solid $opal-grey-light-1;
}

.disabled-link {
    pointer-events: none;
}

a.hover-link {
    text-decoration: none;
    color: $opal-green;

    &:hover {
        text-decoration: underline;
    }
}

.tag {
    background-color: $opal-green-opacity-20;
    color: $opal-green-dark;
    border-radius: 2px;
}

.tag-orange {
  background-color: $opal-orange-opacity-20;
  color: $opal-orange;
  border-radius: 2px;
}

.tag-red {
  background-color: $opal-red-opacity-20;
  color: $opal-red;
  border-radius: 2px;
}

a{
    color: $opal-green;
}

a:hover{
    color: $opal-orange;
}

.width-600{
  width: 600px;
}

.width-500 {
  width: 500px;
}

.width-400{
  width: 400px;
}

.width-370 {
  width: 370px;
}

.width-350 {
  width: 350px;
}

.width-300{
  width: 300px;
}

.width-200{
  width: 200px;
}

.width-35 {
  width: 35px;
}

.width-30 {
  width: 30px;
}

.width-22{
  width: 22px;
}

.height-715 {
  height: 715px;
}

.height-350 {
  height: 350px;
}

.height-320{
  height: 320px;
}

.height-300 {
  height: 300px;
}

.height-82 {
  height: 82px;
}

.form-field{
  margin-bottom: 16px;
}

.k-label{
  margin-bottom: 4px !important;
}

.checkbox-label .k-label{
  margin-bottom: 0px !important;
}

.disable-click{
  pointer-events: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

.flex-schrink-0{
  flex-shrink: 0;
}

.line-height-1{
  line-height: 1;
}

.overflow-y-auto {
  overflow-y: auto;
}

.vertical-align-middle {
  vertical-align: middle;
}