// Background color for kendo inputs


.k-input-solid,.k-input, .k-input.k-disabled {
    background-color: $opal-input-bg;
    color: rgba(0, 0, 0, 0.87);
    font-weight: $opal-font-weight-1;
}

// Check text color for textbox to red when content-alert css class is applied
.k-textbox.content-alert > input{
    color: $opal-alert;
}

// Check text color for textbox to green when content-ok css class is applied
.k-textbox.content-ok > input{
    color: $opal-ok;
}

// Set botton border for focused combobox to opal green color
.k-combobox.k-focus, .k-combobox.k-focus::after{
    border-color: $opal-green;
    
}

// Set botton border for focused combobox to opal green color
.k-textbox.k-focus, .k-textbox.k-focus::after{
    border-color: $opal-green;
    
}

.k-input-solid.k-textbox.ng-invalid.ng-dirty.k-focus::after,
.k-input-solid.k-textbox.ng-invalid.ng-dirty.k-focus{
    border-bottom-color: $opal-green !important;
    
}

// Set color for selected item of list like inputs (e.g. combobox)
.k-list-item.k-selected, .k-list-item.k-selected:hover{
    color: $opal-orange;
}

.k-textarea.k-focus, .k-textarea.k-focus::after{
    border-color: $opal-green;
    
}

.k-input-solid:focus, 
.k-input-solid.k-focus,
.k-input-solid:focus-within
{
    border-bottom-color: $opal-green !important;
}

.k-selection-multiple .k-chip {
    background-color: $opal-orange;
}

.k-selection-multiple .k-chip-content .k-chip-label,
.k-selection-multiple .k-chip-actions .k-chip-remove-action {
    color: white;
}
.k-list-item,
.k-input-inner{
  font-family: $opal-font-family !important;
  font-size: 15px !important;
}

.suppress-validation.k-input-solid.k-invalid, .suppress-validation.k-input-solid.ng-invalid.ng-touched {
    border-bottom-color: rgba(0, 0, 0, 0.87) !important;
}

.k-dropdownlist,
.k-multiselect{
    border-radius: 0px !important;
}