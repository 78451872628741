.searchHit{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
}

.searchHit.codeSnippet pre{
  max-width: 100%; 
}

pre{
  margin-bottom: 0px;
}

.productDocsSearchResult{
  border-radius: 0rem;
  min-width: 600px;
  max-width: 600px;
  box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
}

.productReleaseSearchResult{
  border-radius: 0rem;
  min-width: 600px;
  max-width: 600px;
  box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
}

.marketingMaterialSearchResult{
  border-radius: 0rem;
  min-width: 400px;
  max-width: 400px;
  box-shadow: 0 1px 5px -1px #0003, 0 1px 5px #00000024;
}

.searchBar{

@media (max-width: 650px){
  margin-inline-end: 0px !important;
  min-width: calc(100% - 8px);
  max-width: calc(100% - 8px);
}

@media (min-width: 651px){
  margin-inline-end: 0px !important;
  max-width: 600px;
  min-width: 600px;
}
}

.searchResultsPager{

  .k-pager-info{
    justify-content: flex-start;
  }
  .k-pager{
    
    border-width: 0px;
  
  }

@media (min-width: 1299px){
  margin-inline-start: 0px;
}

@media (max-width: 1299px){
  margin-inline-start: 0px !important;
}

@media (min-width: 1300px) and (max-width: 1400px){
  margin-inline-start: 16px !important;
}

@media (min-width: 1400px) and (max-width: 1599px){
  margin-inline-start: 0px !important;
}

@media (min-width: 1600px){
  margin-inline-start: 16px !important;
}

@media (max-width: 650px){
  margin-inline-end: 0px !important;
  min-width: calc(100% - 8px);
  max-width: calc(100% - 8px);
}

@media (min-width: 651px){
  margin-inline-end: 0px !important;
  max-width: 600px;
  min-width: 600px;
}
}

.search-result-thumbnail{
  height:250px;
  background-color:rgba(0,0,0,0.05); 
  cursor: pointer;
}

.search-result-title{
  color: $opal-green;
  &:hover{
    color: $opal-orange;
  }
}

.search-result-link,
.search-result-link-separator{
  color: $opal-grey-dark;
}