.dialog-footer{
    padding: 12px 16px 12px 16px;
}

.dialog-body{
    padding: 8px 16px 8px 16px;
    border-top: solid 1px $opal-grey;
    border-bottom: solid 1px $opal-grey;
    
}

