.k-checkbox:checked, .k-checkbox.opal-check{
    border-color: $opal-green !important;
    background-color: $opal-green !important;
}

.k-checkbox.checkbox-inactive{
    border-color: $opal-grey !important;
    background-color: $opal-grey !important;
}

.k-checkbox.checkbox-active{
    border-color: $opal-orange !important;
    background-color: $opal-orange !important;
}